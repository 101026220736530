.portfolio_popup_swiper_container {
  margin-bottom: 70px;
}
.portfolio_popup_swiper {
  overflow: visible !important;
}
.portfolio_popup_swiper_img_container {
  border-radius: 15px;
  overflow: hidden;
}
.portfolio_popup_swiper_img_container span {
  width: 100%;
}

.swiper_button_container {
  display: flex;
  align-self: flex-end;
}
.swiper_button {
  align-items: center;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.swiper_end {
  opacity: 0.5;
}
.swiper_button:first-child {
  margin-right: 16px;
}
.portfolio_popup_swiper_img {
  object-fit: cover;
  height: auto;
  /* object-fit: contain;
  height: 400px; */
  min-height: 355px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
  width: calc(100% - 2px);
}

@media screen and (max-width: 1023px) {
  .portfolio_popup_swiper_img {
    min-height: 35vw;
  }
}
@media screen and (max-width: 767px) {
}
