.portfolio_popup_inner_container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: inherit;
}
.portfolio_popup_header {
  /* position: fixed; */
  height: 25px;
  padding: 30px;
  padding-bottom: 50px;
  color: white;
  border-radius: 15px 15px 0 0;
  background-color: #1e1e1e;
  top: 0;
}
.portfolio_popup_body {
  overflow-y: auto !important;
  padding: 25px 30px;
  height: 70%;
  overflow-x: hidden;
  background: var(--bg-secondary);
}
.portfolio_section_control {
  margin: 20px 0;
}
.portfolio_popup_project_logo {
  height: 32px;
}
.portfolio_popup_title {
  font-size: 24px;
  margin-bottom: 20px;
}
.portfolio_popup_content_title {
  font-size: 19px;
  /* color: var(--side-theme-color); */
  color: var(--text-secondary);
  /* color: #656d72; */
  font-family: "Inter";
}
.portfolio_popup_content {
  font-size: 15px;
  margin-top: 20px;
  color: var(--text-primary);
  /* color: #232e35; */
}
.portfolio_popup_content_list {
  padding-left: 30px;
  color: var(--text-primary);
  /* color: #232e35; */
}
.portfolio_popup_content_list_item {
  position: relative;
  margin: 12px 0;
  padding-left: 12px;
}
.portfolio_popup_content_list_item::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: -20px;
  /* background-image: url("../../../../assets/icons/complete.svg"); */
}
.link {
  color: var(--side-theme-color);
  font-weight: bold;
  position: relative;
}
.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.5px;
  background: var(--side-theme-color);
  left: 0;
  bottom: -3px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.link:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

/* .arrow_image {
  margin-left: 2px;
  transition: 0.3s;
} */
.link:hover > .arrow_image {
  margin-left: 10px;
}
.portfolio_popup_skills_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: -5px;
}
.portfolio_popup_skills_inner_container {
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 2px 8px;
  margin: 5px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.13);
}
.portfolio_popup_skills {
  color: #000;
  font-size: 15px;
}
@media screen and (max-width: 1023px) {
  .portfolio_popup_body {
    padding: 25px;
  }
}
@media screen and (max-width: 767px) {
  .portfolio_popup_swiper_container {
    margin-bottom: 48px;
  }
  .portfolio_popup_content_title {
    font-size: 16px;
  }
  .portfolio_popup_content {
    font-size: 12px;
  }
  .portfolio_popup_body {
    padding: 25px 18px;
  }
  .portfolio_popup_skills_container {
    margin-left: -3px;
  }
  .portfolio_popup_skills_inner_container {
    margin: 3px;
  }
  .portfolio_popup_skills {
    font-size: 12px;
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
