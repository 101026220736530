.popup {
  position: fixed; /* Stay in place */
  z-index: 11;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  min-height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.popup_content {
  overflow-x: hidden;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}

/* width */
.popup_content ::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.popup_content ::-webkit-scrollbar-track {
  background-color: #fefefe;
  border-radius: 20px;
}

.popup_content ::-webkit-scrollbar-track:hover {
  background-color: #c7c7c7;
}

/* Handle */
.popup_content ::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136);
  border-radius: 20px;
}

/* Handle on hover */
.popup_content ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.popup_content_container {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  margin-bottom: 40px;
  /* background-color: #fefefe; */
  border-radius: 15px;
  width: 864px;
}

/* The Close Button */
.popup_close {
  color: rgba(255, 255, 255, 0.5);
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 22px;
  transition: 0.2s ease-in-out;
  z-index: 999;
}

.popup_close:hover,
.popup_close:focus {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

@media screen and (max-width: 1023px) {
  .popup_content {
    width: calc(100% - 90px);
    /* // height: 80vh; */
  }
  .popup_content_container {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .popup_content {
    width: calc(100% - 45px);
  }
}
